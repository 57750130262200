import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './Header.css';
import logo from '../../assets/images/logo-white.png';
import Login from '../Login';
import Register from '../Register';
import { changeMenuMobile } from '../../store/actions/GlobalActions';
import {
  changeModalLogin,
  changeModalRegister,
} from '../../store/actions/UserActions';

function Header(props) {
  const {
    userReducer: { user },
    globalReducer: {
      global_city,
      global_cinemas,
      global_cities,
      global_menu_mobile,
    },
    match: {
      params: { city },
    },
    changeModalLogin,
    changeModalRegister,
    changeMenuMobile,
    type,
    menu,
  } = props;
  let cityName = global_city ? global_city : city ? city : '';
  let homeRoute = cityName !== '' ? `/ciudad/${cityName}` : '/';

  const renderMenuCities = (goTo) => {
    let html = global_cities.map((city) => (
      <Link
        to={`/ciudad/${city.slug}${goTo}`}
        key={city.id}
        onClick={() => changeMenuMobile('')}
      >
        {city.nombre}
      </Link>
    ));
    return html;
  };

  const fecha = new Date();
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return (
    <header>
      <Login />
      <Register />
      <div className={`Header ${type}`}>
        <span className="date">
          {fecha.toLocaleDateString('es-CO', options)}
        </span>
        {!user ? (
          <div className="Header_session-menu">
            <>
              <button onClick={() => changeModalLogin('visible')}>LOGIN</button>
              <span> / </span>
              <button onClick={() => changeModalRegister('visible')}>
                REGISTRO
              </button>
            </>
          </div>
        ) : (
          <div className="Header_session-menu">
            <div>
              {/* <Link to="/perfil">{user.nombre.toUpperCase()}</Link> */}
              <Link to="/perfil">PERFIL</Link>
              <span> | </span>
              <Link to="/tickets">TICKETS</Link>
              {/* <span> | </span>
                <Link to="/mis-cupones">CUPONES</Link> */}
            </div>
          </div>
        )}
      </div>
      {menu && (
        <div className="Header_container">
          <div className="Header_content">
            <Link to={homeRoute}>
              <img src={logo} alt="Logo Procinal" />
            </Link>
            <div className="Header_social">
              <a
                href="https://www.facebook.com/procinal"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                href="https://twitter.com/cineprocinal"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter" />
              </a>
              <a
                href="https://www.instagram.com/procinal/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram" />
              </a>
              <a
                href="https://www.youtube.com/user/CinemaProcinal"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-youtube" />
              </a>
            </div>
          </div>
          <div className="Header_menu">
            <button
              className="Header_button-mobile"
              onClick={() => changeMenuMobile('visible')}
            >
              <i className="fas fa-bars" />
            </button>
            <div className={`Header_menu-container ${global_menu_mobile}`}>
              <button
                className="Header_button-close"
                onClick={() => changeMenuMobile('')}
              >
                <i className="fas fa-times" />
              </button>
              <NavLink
                to={homeRoute}
                activeClassName="active"
                className="Header_menu-item"
                onClick={() => changeMenuMobile('')}
              >
                HOME
              </NavLink>
              {global_city ? (
                <NavLink
                  to={`${homeRoute}/cartelera`}
                  activeClassName="active"
                  className="Header_menu-item"
                  onClick={() => changeMenuMobile('')}
                >
                  CARTELERA
                </NavLink>
              ) : (
                <div className="Header_menu-item">
                  CARTELERA
                  <div className="Header_submenu">
                    {renderMenuCities('/cartelera')}
                  </div>
                </div>
              )}
              <NavLink
                to="/proximos-estrenos"
                activeClassName="active"
                className="Header_menu-item"
                onClick={() => changeMenuMobile('')}
              >
                PRÓXIMOS ESTRENOS
              </NavLink>
              <div className="Header_menu-item">
                {city ? (
                  <>
                    {global_cinemas.length > 1 ? (
                      <NavLink
                        to={`${homeRoute}/teatros`}
                        activeClassName="active"
                        className="Header_menu-item"
                        onClick={() => changeMenuMobile('')}
                      >
                        TEATROS
                      </NavLink>
                    ) : global_cinemas.length ? (
                      <NavLink
                        to={`${homeRoute}/teatros/${global_cinemas[0].nombre}`}
                        activeClassName="active"
                        className="Header_menu-item"
                        onClick={() => changeMenuMobile('')}
                      >
                        TEATROS
                      </NavLink>
                    ) : (
                      <>TEATROS</>
                    )}
                  </>
                ) : (
                  <>TEATROS</>
                )}
                <div className="Header_submenu">
                  {global_cinemas.length ? (
                    <>
                      {global_cinemas.map((cinema) => (
                        <Link
                          key={cinema.id}
                          to={`${homeRoute}/teatros/${cinema.nombre}`}
                          onClick={() => changeMenuMobile('')}
                        >
                          {cinema.nombre_completo
                            ? cinema.nombre_completo
                            : cinema.nombre.toUpperCase()}
                        </Link>
                      ))}
                    </>
                  ) : (
                    <>{renderMenuCities('/teatros')}</>
                  )}
                </div>
              </div>
              {global_city === 'bogota' && (
                <NavLink
                  to={`${homeRoute}/teatros/imax`}
                  activeClassName="active"
                  className="Header_menu-item"
                  onClick={() => changeMenuMobile('')}
                >
                  IMAX
                </NavLink>
              )}
              {global_city ? (
                <NavLink
                  to={`${homeRoute}/confiteria`}
                  activeClassName="active"
                  className="Header_menu-item"
                >
                  CONFITERÍA
                </NavLink>
              ) : (
                <div className="Header_menu-item">
                  CONFITERÍA
                  <div className="Header_submenu">
                    {renderMenuCities('/confiteria')}
                  </div>
                </div>
              )}
              <NavLink
                to="/cinemas"
                activeClassName="active"
                className="Header_menu-item"
                onClick={() => changeMenuMobile('')}
              >
                CINE+
              </NavLink>
              <NavLink
                to="/corporativo"
                activeClassName="active"
                className="Header_menu-item"
                onClick={() => changeMenuMobile('')}
              >
                CORPORATIVO
              </NavLink>
              <NavLink
                to="/contactanos"
                activeClassName="active"
                className="Header_menu-item"
                onClick={() => changeMenuMobile('')}
              >
                CONTÁCTANOS
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

Header.defaultProps = {
  type: 'red',
  menu: false,
};

const mapStateToProps = ({ globalReducer, userReducer }) => {
  return {
    globalReducer,
    userReducer,
  };
};

const mapDispatchToProps = {
  changeModalLogin,
  changeModalRegister,
  changeMenuMobile,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
